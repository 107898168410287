<template>
  <div>
    <el-form
      label-position="top"
      size="mini"
      :rules="rules"
      :model="menu"
      ref="menu"
    >
      <div class="page-header panel">
        <el-row :gutter="20">
          <el-col :span="12">
            <h3 class="title">{{ this.outlet.full_name_eng }}</h3>
          </el-col>
          <el-col :span="12" class="text-right mt-5">
            <a to="/menu" class="mr10" @click="$router.go(-1)">
              <el-button size="mini">Буцах</el-button>
            </a>
            <a>
              <el-button size="mini" type="success" @click="save" disabled
                >Хадгалах</el-button
              >
            </a>
          </el-col>
        </el-row>
      </div>
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="panel">
            <h3 class="mt0">Ерөнхий мэдээлэл</h3>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Нэр" prop="name_mon">
                  <el-input v-model="menu.name_mon"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Нэр англи" prop="name_eng">
                  <el-input v-model="menu.name_eng"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Тайлбар" prop="desc_mon">
                  <el-input
                    type="textarea"
                    rows="2"
                    v-model="menu.desc_mon"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Тайлбар англи" prop="desc_eng">
                  <el-input
                    type="textarea"
                    rows="2"
                    v-model="menu.desc_eng"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="Дотоод ангилал new" prop="menu_categories">
                  <el-select
                    v-model="menu.menu_categories"
                    value-key="menu_category_mon"
                    filterable=""
                    remote=""
                    reserve-keyword
                    placeholder="хоол сонгох"
                  >
                    <el-option
                      v-for="(item, index) in outlet.menu_categories"
                      :key="index"
                      :label="item.menu_category_mon"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  label="Үндсэн ангилал /монгол/"
                  prop="category_mon"
                >
                  <el-select
                    v-model="menu.category_mon"
                    multiple
                    @change="categoryChange()"
                    class="dblock"
                  >
                    <el-option
                      v-for="(item, index) in categories_mon"
                      :key="index.key"
                      :value="item"
                      >{{ item }}
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="Үндсэн ангилал /англи/"
                  prop="category_eng"
                >
                  <el-select
                    v-model="menu.category_eng"
                    multiple
                    class="dblock"
                    @change="categoryChange1()"
                  >
                    <el-option
                      v-for="(item, index) in categories_eng"
                      :key="index.key"
                      :value="item"
                      >{{ item }}
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Хайх ангилал /монгол/" prop="category_mon">
                  <el-select
                    v-model="menu.menu_search_category_mon"
                    multiple
                    @change="searchCategoryChange()"
                    class="dblock"
                  >
                    <el-option
                      v-for="(item, index) in search_categories_mon"
                      :key="index.key"
                      :value="item"
                      >{{ item }}
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Хайх ангилал /англи/" prop="category_eng">
                  <el-select
                    v-model="menu.menu_search_category_eng"
                    multiple
                    class="dblock"
                    @change="searchCategoryChange1()"
                  >
                    <el-option
                      v-for="(item, index) in search_categories_eng"
                      :key="index.key"
                      :value="item"
                      >{{ item }}
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Зураг">
                  <base64-upload
                    v-if="menu.img_url !== null && menu.img_url !== ''"
                    style="width: 100px; height: 100px"
                    :imageSrc="menu.img_url"
                    @change="onChangeImage"
                  ></base64-upload>
                  <a v-else v-loading="loadUpload">
                    <base64-upload
                      :imageSrc="imageNull"
                      @change="onChangeImage"
                    ></base64-upload>
                  </a>
                  <el-input v-model="menu.img_url" disabled></el-input>
                </el-form-item>
                <el-form-item label="Үнэ" prop="menu_price">
                  <el-currency-input
                    v-model="menu.menu_price"
                    @input="setData"
                  ></el-currency-input>
                </el-form-item>
                <el-form-item label="Эрэмбэ" prop="sort_number">
                  <el-input type="number" v-model="menu.menu_sort"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="Бүтээгдэхүүн бэлэн болох хугацаа"
                  :inline="true"
                >
                  <el-input
                    placeholder="20"
                    v-model="menu.menu_avg_time"
                    style="display:inline-block; width: 100px"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="panel">
            <h3 class="mt0">Нэмэлт мэдээлэл</h3>
            <el-form-item>
              <el-checkbox v-model="menu.is_active">
                Идэвхитэй бүтээгдэхүүн
              </el-checkbox>
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="menu.is_variant"
                >Сонголттой эсэх</el-checkbox
              >
            </el-form-item>

            <el-form-item label="Сонголтууд" v-if="menu.is_variant == true">
              <!-- {{ this.menu.variants }} -->
              <el-tree
                v-if="
                  Object.prototype.hasOwnProperty.call(this.menu, 'variants')
                "
                :data="variantDataList"
                :props="{ children: 'options', label: 'label' }"
                :default-expand-all="true"
              >
                <span class="custom-tree-node" slot-scope="{ node, data }">
                  <span>{{ node.label }}</span>
                  <span
                    v-if="Object.prototype.hasOwnProperty.call(data, 'options')"
                  >
                    <el-button
                      type="text"
                      size="mini"
                      @click="() => editVariant(node, data)"
                    >
                      Засах
                    </el-button>
                    <el-button
                      type="text"
                      size="mini"
                      @click="() => removeVariant(node, data)"
                    >
                      Устгах
                    </el-button>
                  </span>
                </span>
              </el-tree>
              <el-button
                style="margin-top:20px;"
                type="primary"
                v-if="menu.is_variant == true"
                @click="
                  () => {
                    dialogType = 'add';
                    isShowVariantsDialog = true;
                  }
                "
                >Сонголт нэмэх</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="menu.is_special">Онцлох хоол</el-checkbox>
            </el-form-item>
            <el-col :span="12" v-if="menu.is_special == true">
              <el-form-item label="Монгол" prop="special_text">
                <el-select
                  v-model="menu.special_text"
                  multiple
                  filterable
                  allow-create
                  class="dblock"
                >
                  <el-option
                    v-for="(item, index) in special_text_mon_page"
                    :key="index"
                    :value="item"
                    >{{ item }}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="menu.is_special == true">
              <el-form-item label="Англи" prop="special_text_eng">
                <el-select
                  v-model="menu.special_text_eng"
                  multiple
                  filterable
                  allow-create
                  class="dblock"
                >
                  <el-option
                    v-for="(item, index) in special_text_eng_page"
                    :key="index"
                    :value="item"
                    >{{ item }}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-form-item>
              <el-checkbox v-model="menu.is_time_limit">
                Хугацаа тохируулах
              </el-checkbox>
            </el-form-item>
            <el-col :span="12" v-if="menu.is_time_limit == true">
              <el-form-item label="Монгол" prop="time_limit_text">
                <el-select
                  v-model="menu.time_limit_text"
                  multiple
                  filterable
                  allow-create
                  class="dblock"
                >
                  <el-option
                    v-for="(item, index) in time_limit_text_mon_page"
                    :key="index"
                    :value="item"
                    >{{ item }}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="menu.is_time_limit == true">
              <el-form-item label="Англи" prop="time_limit_text_eng">
                <el-select
                  v-model="menu.time_limit_text_eng"
                  multiple
                  filterable
                  allow-create
                  class="dblock"
                >
                  <el-option
                    v-for="(item, index) in time_limit_text_eng_page"
                    :key="index"
                    :value="item"
                    >{{ item }}
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-form-item>
              <el-checkbox v-model="menu.is_suggest"
                >Санал болгох бүтээгдэхүүн болгох</el-checkbox
              >
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="menu.is_adult">
                Насанд хүрсэн эсэх
              </el-checkbox>
            </el-form-item>
            <el-form-item>
              <el-switch
                v-model="menu.is_draft"
                active-text="Нуух"
                inactive-text="Харагдуулах"
              >
              </el-switch>
            </el-form-item>
            <el-form-item>
              <el-switch
                v-model="selectBadge"
                active-text="Бадже харах"
                inactive-text="Бадже харахгүй"
              >
              </el-switch>
            </el-form-item>
            <el-form-item
              v-if="this.selectBadge == true"
              style="marginTop:20px"
            >
              <el-select
                v-model="menuFormBadge.type"
                placeholder="Бадже сонгоно уу"
              >
                <el-option label="Онцгой" value="special"></el-option>
                <el-option label="Хямдарсан" value="sale"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="this.selectBadge == true"
              label="Бадже монгол гарчиг"
              prop="name_mon"
            >
              <el-input
                v-model="menuFormBadge.name_mon"
                type="textarea"
                placeholder="Бадже монгол гарчиг бичнэ үү!!!"
                :rows="1"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="this.selectBadge == true"
              label="Бадже англи гарчиг"
              prop="name_eng"
            >
              <el-input
                v-model="menuFormBadge.name_eng"
                type="textarea"
                placeholder="Бадже англи гарчиг бичнэ үү!!!"
                :rows="1"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="this.selectBadge == true"
              label="Үргэлжлэх хугацаа"
            >
              <el-col :span="11">
                <el-date-picker
                  type="date"
                  placeholder="Эхлэх огноо"
                  v-model="menu.badge_start_date"
                  style="width: 100%;"
                  value-format="yyyy-MM-dd HH:mm:ss"
                ></el-date-picker>
              </el-col>
              <el-col class="line" :span="2">-</el-col>
              <el-col :span="11">
                <el-date-picker
                  type="date"
                  placeholder="Дуусах огноо"
                  v-model="menu.badge_end_date"
                  style="width: 100%;"
                  value-format="yyyy-MM-dd HH:mm:ss"
                ></el-date-picker>
              </el-col>
            </el-form-item>
            <el-form-item
              style="margin-top:20px;"
              label="Нэмэлт бүтээгдэхүүн эсэх"
              prop="is_addon"
            >
              <el-switch
                v-model="menu.is_addon"
                active-text="Тийм"
                inactive-text="Үгүй"
                @change="onChangeIsAddon"
              >
              </el-switch>
            </el-form-item>
            <el-form-item v-if="menu.is_addon" size="small">
              <el-select
                v-model="menu.addon_menus"
                multiple
                value-key="name_mon"
                filterable=""
                remote=""
                reserve-keyword
                placeholder="хоол сонгох"
                @change="onChangeAddonMenuSelect"
              >
                <el-option
                  v-for="(tempMenu, tempMenuIndex) in outletMenus"
                  :key="tempMenuIndex"
                  :label="tempMenu.name_mon"
                  :value="tempMenu"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <div v-if="menu.is_addon">
              <div
                v-for="(addOn, addOnIndex) in menu.addon_menus"
                :key="addOnIndex"
              >
                <el-form-item
                  label="Үнэ"
                  :prop="'addon_menus.' + addOnIndex + '.addon_price'"
                  :rules="{
                    required: true,
                    message: 'price is required',
                    trigger: 'blur'
                  }"
                >
                  <el-col :span="6">
                    <el-input v-model="addOn.name_mon" disabled></el-input>
                  </el-col>
                  <el-col class="line" :span="1">-</el-col>
                  <el-col :span="6">
                    <el-currency-input
                      placeholder="Үнэ оруулна уу"
                      v-model="addOn.addon_price"
                    ></el-currency-input>
                  </el-col>
                  <el-col class="line" :span="1">-</el-col>
                  <el-col :span="6">
                    <el-input-number
                      v-model="addOn.addon_limit"
                    ></el-input-number>
                  </el-col>
                </el-form-item>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="panel">
            <h3 class="mt0">Бүтээгдэхүүний шинж чанар</h3>
            <el-form-item>
              <el-table
                :data="propertiesData"
                style="width: 100%"
                max-height="350"
              >
                <el-table-column prop="name" label="Төрөл" width="100">
                  <template slot-scope="scope">
                    <el-select
                      style="width:100px"
                      v-model="scope.row.name"
                      value-key="name"
                      filterable
                      placeholder="шинж чанар сонгох"
                      @change="changeProperty(scope.row.name)"
                    >
                      <el-option
                        v-for="(item, index) in productProperties"
                        :key="index"
                        :label="item.name"
                        :value="item.name"
                      >
                        <span>{{ item.name }}</span>
                        <span
                          style="float: right; color: red; font-size: 13px"
                          >{{ item.service }}</span
                        >
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="value" label="" width="100">
                  <template slot-scope="scope">
                    <el-select
                      style="width:100px"
                      v-model="scope.row.value"
                      value-key="value"
                    >
                      <el-option
                        v-for="(item, index) in propertiesValue"
                        :key="index"
                        :label="item.value"
                        :value="item.value"
                      >
                        <span>{{ item.value }}</span>
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column label="Үйлдэл" width="100">
                  <template slot-scope="scope">
                    <el-button
                      @click="deleteProperties(scope.$index)"
                      type="text"
                      size="mini"
                    >
                      Устгах
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-button @click="addProperties()" type="primary"
                >Нэмэх</el-button
              >
            </el-form-item>
          </div>
        </el-col>
        <el-col
          :span="12"
          style="margin-top: 2%;"
          v-if="this.$route.name !== 'addMenu'"
        >
          <div class="panel">
            <h3 class="mt0">Зассан шалтгаан</h3>
            <el-form-item prop="desc">
              <el-input
                type="textarea"
                rows="2"
                v-model="menu.desc"
                placeholder="Зассан шалтгаан оруулна уу!!!"
              ></el-input>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog :visible.sync="imagePreviewDialog.isShow">
      <img width="100%" :src="imagePreviewDialog.imgUrl" />
    </el-dialog>
    <variant-dialog
      :isShow="isShowVariantsDialog"
      :editData="variantEditData"
      :variantIndex="variantIndex"
      :dialogType="variantDialogType"
      :saveFunction="saveVariant"
      :closeFunction="variantDialogClose"
    ></variant-dialog>
  </div>
</template>
<script>
import service from "@/helpers/services.js";
import ElCurrencyInput from "./ElCurrencyInput";
import Base64Upload from "vue-base64-upload";
import { getUserName, getGroups } from "../utils/auth";
import { toBase64, formatPrice } from "../helpers/helper";
import VariantDialog from "./menu/VariantDialog.vue";
export default {
  name: "AddMenu",
  components: {
    Base64Upload,
    ElCurrencyInput,
    VariantDialog
  },
  computed: {
    variantDataList: {
      cache: false,
      get() {
        return this.menu.variants;
      }
    }
  },
  created() {
    VariantDialog;
    service.getActiveSuggestMenus(true).then(res => {
      for (var i = 0; i < res.data.data.length; i++) {
        this.special_text_mon_page.push(res.data.data[i].name_mon);
        this.special_text_eng_page.push(res.data.data[i].name_eng);
      }
    });
    service.getAllMenuLimit().then(res => {
      for (var i = 0; i < res.length; i++) {
        this.time_limit_text_mon_page.push(res[i].name_mon);
        this.time_limit_text_eng_page.push(res[i].name_eng);
      }
    });
    if (this.$route.name === "editMenu") {
      if (this.$route.query.menu_id !== null) {
        this.getMenuById(this.$route.query.menu_id);
        this.getOutletById(this.$route.query.outlet_id);
      }
    } else if (this.$route.name === "addMenu") {
      if (this.$route.query.outlet_id !== null) {
        this.getOutletById(this.$route.query.outlet_id);
      }
    }
    getGroups().then(data => {
      this.role = data;
    });
    this.getSearchCategories();
    this.getMainCategories();
  },
  data() {
    return {
      options: {
        distractionFree: false,
        currency: null,
        precision: 0,
        autoDecimalMode: false,
        valueAsInteger: true,
        hideGroupingSymbol: false
      },
      searchCategoryList: [],
      mainCategoryList: [],
      role: [],
      special_text_eng_page: [],
      special_text_mon_page: [],
      time_limit_text_eng_page: [],
      time_limit_text_mon_page: [],
      categories_mon: [],
      categories_eng: [],
      search_categories_mon: [],
      search_categories_eng: [],
      loadUpload: false,
      imageNull: "none",
      headers: {
        outlet_name: "",
        menu_name: ""
      },
      outlet: {},
      productProperties: [],
      rules: {
        name_mon: [
          {
            required: true,
            message: "Хоолны монгол нэр оруулна уу",
            trigger: "blur"
          }
        ],
        name_eng: [
          {
            required: true,
            message: "Хоолны англи нэр оруулна уу",
            trigger: "blur"
          }
        ],
        desc_mon: [
          {
            required: true,
            message: "Mонгол тайлбар оруулаагүй байна.",
            trigger: "blur"
          }
        ],
        desc_eng: [
          {
            required: true,
            message: "Англи тайлбар оруулаагүй байна.",
            trigger: "blur"
          }
        ],
        menu_price: [
          {
            required: true,
            message: "Үнэ оруулаагүй байна.",
            trigger: "blur"
          }
        ],
        desc: [
          {
            required: true,
            message: "Зассан шалтгаан оруулна уу.",
            trigger: "blur"
          }
        ],
        addon_price: [
          {
            required: true,
            message: "үнэ оруул",
            trigger: "blur"
          }
        ],
        menu_categories: [
          {
            required: true,
            message: "Дотоод ангилал оруулна уу",
            trigger: "blur"
          }
        ]
      },
      menu: {
        is_addon: false,
        is_time_limit: false,
        name_mon: "",
        name_eng: "",
        menu_categories: null,
        menu_search_category_mon: "",
        menu_search_category_eng: "",
        outlet_name: "",
        badge_start_date: null,
        badge_end_date: null,
        desc_mon: "",
        desc_eng: "",
        service: "",
        is_active: true,
        is_special: true,
        is_suggest: true,
        menu_price: 0,
        avg_time: [20, 40],
        menu_avg_time: 0,
        img_url: null,
        special_text: [],
        special_text_eng: [],
        time_limit_text_eng: [],
        time_limit_text: [],
        menu_sort: 0,
        category_mon: [],
        category_eng: [],
        desc: "",
        addon_menus: [],
        is_adult: false,
        outlet_id: null,
        properties: []
      },
      propertiesData: [],
      service: "",
      selectBadge: false,
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      menuFormBadge: {
        type: "",
        name_mon: "",
        name_eng: ""
      },
      imagePreviewDialog: {
        isShow: false,
        imgUrl: ""
      },
      showUpload: true,
      isShowVariantsDialog: false,
      variantOptions: [],
      tempVariant: {
        variant_name_mon: "",
        variant_name_eng: ""
      },
      tempVariantOption: {
        option_name_mon: "",
        option_name_eng: "",
        option_price: 0
      },
      variantEditData: {},
      variantDialogType: "add",
      variantIndex: -1,
      outletMenus: [],
      menuCategories: [],
      service_list: [],
      propertiesValue: [],
      oldImgUrl: "",
      isMenuDataLoaded: false
    };
  },
  methods: {
    setData(price) {
      if (this.menu.is_addon === true && this.menu.addon_menus.length > 0) {
        this.menu.addon_menus.forEach(element => {
          element.addon_price = price;
        });
      }
    },
    getSearchCategories() {
      service.getSearchCategories().then(res => {
        this.searchCategoryList = res.data.data;
      });
    },
    getMainCategories() {
      service.getCategories().then(categoriesResponse => {
        this.mainCategoryList = categoriesResponse;
      });
    },
    searchCategoryChange() {
      this.menu.menu_search_category_eng = [];
      this.menu.menu_search_category_mon.forEach(element => {
        this.searchCategoryList.forEach(element1 => {
          if (element === element1.name_mon) {
            this.menu.menu_search_category_eng.push(element1.name_eng);
          }
        });
      });
    },
    searchCategoryChange1() {
      this.menu.menu_search_category_mon = [];
      this.menu.menu_search_category_eng.forEach(element => {
        this.searchCategoryList.forEach(element1 => {
          if (element === element1.name_eng) {
            this.menu.menu_search_category_mon.push(element1.name_mon);
          }
        });
      });
    },
    categoryChange() {
      this.menu.category_eng = [];
      this.menu.category_mon.forEach(element => {
        this.mainCategoryList.forEach(element1 => {
          if (element === element1.name_mon) {
            this.menu.category_eng.push(element1.name_eng);
          }
        });
      });
    },
    categoryChange1() {
      this.menu.category_mon = [];
      this.menu.category_eng.forEach(element => {
        this.mainCategoryList.forEach(element1 => {
          if (element === element1.name_eng) {
            this.menu.category_mon.push(element1.name_mon);
          }
        });
      });
    },
    onChangeImage(file) {
      this.loadUpload = true;
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (
        !(
          file.type === "image/png" ||
          file.type === "image/jpeg" ||
          file.type === "image/jpg"
        )
      ) {
        this.$message.error(
          "Зурагны төрөл буруу байна. png эсвэл jpg байх ёстой."
        );
        this.imageNull = "none";
        this.loadUpload = false;
      } else if (!isLt2M) {
        this.$message({
          type: "error",
          duration: 5000,
          showClose: true,
          message: "Зурагны хэмжээ ихдээ 2MB байх ёстой."
        });
        this.imageNull = "none";
        this.menu.img_url = this.oldImgUrl;
        this.loadUpload = false;
      } else {
        if (!this.menu.name_eng) {
          this.$message.error("Зурагны aнгли нэрийг заавал оруулах ёстой!");
          this.imageNull = "none";
          this.loadUpload = false;
        } else {
          let payload = {};
          payload.file = file.base64;
          payload.outlet_name = this.outlet.outlet_name;
          payload.menu_name = this.menu.name_eng;
          service.uploadImage(payload).then(res => {
            this.menu.img_url = res.img_url;
            this.loadUpload = false;
          });
        }
      }
      return isLt2M;
    },
    save() {
      this.$refs.menu.validate(valid => {
        if (valid) {
          if (this.loadUpload) {
            this.$message.error("Зураг хуулж дуусаагүй байна!");
          } else {
            getUserName().then(name => {
              this.menu.user = name.attributes.email.split("@")[0];
              this.menu.properties = this.propertiesData;
              if (this.$route.name === "addMenu") {
                if (!this.menu.is_addon) {
                  this.menu.addon_menus = [];
                }
                if (this.selectBadge === false) {
                  this.menuFormBadge = null;
                  this.menu.badge_end_date = null;
                  this.menu.badge_start_date = null;
                  this.callFunction();
                } else {
                  if (this.menuFormBadge.name_mon == null) {
                    this.$notify.error({
                      title: "Error",
                      message: "Бадже сонгоно уу"
                    });
                  } else {
                    this.menu.badges = this.menuFormBadge;
                    this.callFunction();
                  }
                }
              } else if (this.$route.name === "editMenu") {
                this.menu.id = this.$route.query.menu_id;
                if (!this.menu.is_addon) {
                  this.menu.addon_menus = [];
                }
                if (this.selectBadge === false) {
                  this.menuFormBadge = null;
                  this.menu.badge_end_date = null;
                  this.menu.badge_start_date = null;
                  this.menu.badges = this.menuFormBadge;
                  if (this.menu.desc == "" || this.menu.desc == null) {
                    this.menu.desc = "hooson bn";
                  } else {
                    this.menu.desc = this.menu.desc;
                  }
                  service.updateMenu(this.menu).then(response => {
                    this.$notify({
                      title: "Амжилттай",
                      message: response.message,
                      type: "success"
                    });
                  });
                } else {
                  if (this.menuFormBadge.name_mon == null) {
                    this.$notify.error({
                      title: "Error",
                      message: "Бадже сонгоно уу"
                    });
                  } else {
                    this.menu.badges = this.menuFormBadge;
                    if (this.menu.desc == "" || this.menu.desc == null) {
                      this.menu.desc = "hooson bn";
                    } else {
                      this.menu.desc = this.menu.desc;
                    }
                    service.updateMenu(this.menu).then(response => {
                      this.$notify({
                        title: "Амжилттай",
                        message: response.message,
                        type: "success"
                      });
                    });
                  }
                }
                this.$router.go(-1);
              }
            });
          }
        } else {
          this.$notify({
            title: "Анхааруулга",
            message: "Зарим талбарыг бөглөөгүй байна.",
            type: "warning"
          });
          return false;
        }
      });
    },
    callFunction() {
      this.menu.badges = this.menuFormBadge;
      this.menu.outlet_id = this.$route.query.outlet_id;
      service.addMenu(this.menu).then(response => {
        this.$notify({
          title: "Амжилттай",
          message: response.message,
          type: "success"
        });
        this.menu = {
          name_mon: "",
          name_eng: "",
          menu_category_eng: "",
          menu_category_mon: "",
          menu_categories: null,
          outlet_name: "",
          desc_mon: "",
          desc_eng: "",
          service: "",
          is_active: true,
          is_special: true,
          is_time_limit: true,
          is_suggest: true,
          is_adult: false,
          menu_price: 0,
          avg_time: [],
          menu_avg_time: 0,
          special_text: [],
          special_text_eng: [],
          time_limit_text: [],
          time_limit_text_eng: [],
          img_url: null,
          menu_sort: 0,
          category_mon: [],
          category_eng: []
        };
        this.getOutletById(this.$route.query.outlet_id);
        this.$router.go(-1);
      });
    },
    async getOutletById(id) {
      const payload = { includeFields: [] };
      await service.getOutletById(id, payload).then(outletResponse => {
        this.headers.outlet_name = outletResponse.data.data.outlet_name;
        this.outlet = outletResponse.data.data;
        this.menu.outlet_name = outletResponse.data.data.outlet_name;
        this.service = outletResponse.data.data.service;
        this.menu.service = outletResponse.data.data.service;
        this.service_list = outletResponse.data.data.service_list;
        this.categories_mon = outletResponse.data.data.main_category_mon;
        this.categories_eng = outletResponse.data.data.main_category_eng;
        this.search_categories_mon =
          outletResponse.data.data.search_category_mon;
        this.search_categories_eng =
          outletResponse.data.data.search_category_eng;
        if (this.menu.is_addon) {
          this.getMenusByOutlet();
        }
      });
      this.getProductProperties(this.service_list);
    },
    getMenuById(id) {
      service.getMenuById(id).then(menuResponse => {
        this.headers.menu_name = menuResponse.data.data.name_eng;
        this.oldImgUrl = menuResponse.data.data.img_url;
        this.menu = JSON.parse(JSON.stringify(menuResponse.data.data));
        this.propertiesData = this.menu.properties;
        if (!Object.prototype.hasOwnProperty.call(this.menu, "is_addon")) {
          this.menu.is_addon = false;
        }
        if (this.menu.is_addon) {
          this.getMenusByOutlet();
          setTimeout(() => {
            menuResponse.data.data.addon_menus.forEach(el => {
              this.menu.addon_menus.forEach(el2 => {
                if (el.pkey === el2.pkey) el2.addon_price = el.addon_price;
              });
            });
          }, 500);
        }
        if (menuResponse.data.data.badges !== null) {
          this.menuFormBadge.type = menuResponse.data.data.badges.type;
          this.menuFormBadge.name_mon = menuResponse.data.data.badges.name_mon;
          this.menuFormBadge.name_eng = menuResponse.data.data.badges.name_eng;
          this.menu.badge_start_date = menuResponse.data.data.badge_start_date;
          this.menu.badge_end_date = menuResponse.data.data.badge_end_date;
          this.selectBadge = true;
        }
        if (Object.prototype.hasOwnProperty.call(this.menu, "variants")) {
          this.menu.variants.map(el => {
            if (
              Object.prototype.hasOwnProperty.call(el, "variant_name_mon") &&
              Object.prototype.hasOwnProperty.call(el, "options")
            ) {
              el.label = el.variant_name_mon;
              el.options.map(elOption => {
                if (
                  Object.prototype.hasOwnProperty.call(
                    elOption,
                    "option_name_mon"
                  )
                ) {
                  let tempLabel = elOption.option_name_mon;
                  if (parseInt(elOption.option_price) > 0) {
                    tempLabel = `${elOption.option_name_mon} - ${formatPrice(
                      elOption.option_price
                    )}₮`;
                  }
                  elOption.label = tempLabel;
                }
              });
            }
          });
        }
      });
    },
    getFile(file) {
      this.loadUpload = true;
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (
        !(
          file.raw.type === "image/png" ||
          file.raw.type === "image/jpeg" ||
          file.raw.type === "image/jpg"
        )
      ) {
        this.$message.error(
          "Зурагны төрөл буруу байна. png эсвэл jpg байх ёстой."
        );
        this.imageNull = "none";
        this.loadUpload = false;
        return false;
      } else if (!isLt2M) {
        this.$message.error("Зурагны хэмжээ ихдээ 1MB байх ёстой!");
        this.imageNull = "none";
        this.loadUpload = false;
        return false;
      } else {
        if (!this.menu.name_eng) {
          this.$message.error("Зурагны aнгли нэрийг заавал оруулах ёстой!");
          this.imageNull = "none";
          this.loadUpload = false;
          return false;
        } else {
          toBase64(file.raw).then(res => {
            let payload = {};
            payload.file = res.replace(/^data:image\/[a-z]+;base64,/, "");
            payload.outlet_name = this.outlet.outlet_name;
            payload.menu_name = this.menu.name_eng;
            service.uploadImage(payload).then(res => {
              this.menu.img_url = res.img_url;
              this.showUpload = false;
              this.loadUpload = false;
            });
          });
        }
      }
      return isLt2M;
    },
    handlePictureCardPreview(file) {
      this.imagePreviewDialog.imgUrl = file.url;
      this.imagePreviewDialog.isShow = true;
    },
    beforeImageUpload(file) {
      let isName = false;
      let isFileType = false;
      let isFileSize = file.size / 1024 / 1024 < 0.5;

      // FileType START
      if (["image/png", "image/jpeg", "image/jpg"].includes(file.type)) {
        isFileType = true;
      } else {
        this.$message.error(
          "Зурагны төрөл буруу байна. png эсвэл jpg байх ёстой."
        );
      }
      // FileType END

      // FileSize START
      if (!isFileSize) {
        this.$message.error("Зурагны хэмжээ ихдээ 1MB байх ёстой!");
      }
      // FileSize END

      // Name Check START
      if (this.menu.name_eng) {
        isName = true;
      } else {
        isName = false;
        this.$message.error("Зурагны aнгли нэрийг заавал оруулах ёстой!");
      }
      // Name Check END
      return isFileType && isFileSize && isName;
    },
    removeImage() {
      this.showUpload = true;
      this.menu.img_url = null;
    },
    onClickAddVariant() {
      if (!Object.prototype.hasOwnProperty.call(this.menu, "variants")) {
        this.menu.variants = [];
      }
      let tempData = {
        variant_name_mon: "",
        variant_name_eng: "",
        options: []
      };
      this.menu.variants.push(tempData);
      this.$forceUpdate();
    },
    variantDialogClose() {
      this.isShowVariantsDialog = false;
      this.variantDialogType = "add";
      this.variantEditData = {};
      this.variantIndex = -1;
    },
    editVariant(node, data) {
      this.variantEditData = JSON.parse(JSON.stringify(data));
      this.variantIndex = this.menu.variants.findIndex(
        el =>
          el.variant_name_mon == data.variant_name_mon &&
          el.variant_name_eng == data.variant_name_eng
      );
      this.variantDialogType = "edit";
      this.isShowVariantsDialog = true;
    },
    removeVariant(node, data) {
      let removeIndex = this.menu.variants.findIndex(
        el =>
          el.variant_name_mon == data.variant_name_mon &&
          el.variant_name_eng == data.variant_name_eng
      );
      this.menu.variants.splice(removeIndex, 1);
      this.$forceUpdate();
    },
    saveVariant(variant, dialogType, variantIndex) {
      if (!Object.prototype.hasOwnProperty.call(this.menu, "variants")) {
        this.menu.variants = [];
      }
      if (dialogType == "add") {
        this.menu.variants.push(JSON.parse(JSON.stringify(variant)));
      } else {
        this.menu.variants[variantIndex] = JSON.parse(JSON.stringify(variant));
      }
      this.$forceUpdate();
      if (Object.prototype.hasOwnProperty.call(this.menu, "variants")) {
        this.menu.variants.map(el => {
          if (
            Object.prototype.hasOwnProperty.call(el, "variant_name_mon") &&
            Object.prototype.hasOwnProperty.call(el, "options")
          ) {
            el.label = el.variant_name_mon;
            el.options.map(elOption => {
              if (
                Object.prototype.hasOwnProperty.call(
                  elOption,
                  "option_name_mon"
                )
              ) {
                let tempLabel = elOption.option_name_mon;
                if (parseInt(elOption.option_price) > 0) {
                  tempLabel = `${elOption.option_name_mon} - ${formatPrice(
                    elOption.option_price
                  )}₮`;
                }
                elOption.label = tempLabel;
              }
            });
          }
        });
      }
      this.variantDialogClose();
      this.$forceUpdate();
      this.menu.is_variant = !this.menu.is_variant;
      setTimeout(() => {
        this.menu.is_variant = !this.menu.is_variant;
      }, 10);
    },
    getMenusByOutlet() {
      let temp = {
        outlet_name: this.outlet.outlet_name,
        outlet_id: this.$route.query.outlet_id
      };
      service.getMenusByOutlet(temp.outlet_id, "", "").then(menuResponse => {
        this.outletMenus = menuResponse.data.data;
      });
    },
    onChangeAddonMenuSelect(addOnMenuList) {
      let tempArr = [];
      addOnMenuList.forEach(el => {
        let temp = {
          pkey: el.pkey,
          name_mon: el.name_mon,
          name_eng: el.name_eng,
          outlet_name: el.outlet_name,
          outlet_id: this.$route.query.outlet_id,
          addon_price: el.addon_price,
          addon_limit: Object.prototype.hasOwnProperty.call(el, "addon_limit")
            ? el.addon_limit
            : 0,
          menu_id: Object.prototype.hasOwnProperty.call(el, "_id")
            ? el._id
            : el.menu_id
        };
        tempArr.push(temp);
      });
      this.menu.addon_menus = JSON.parse(JSON.stringify(tempArr));
      this.$forceUpdate();
    },
    onChangeIsAddon(value) {
      if (value) {
        this.getMenusByOutlet();
      }
    },
    getProductProperties(service_list) {
      const payload = {};
      payload.service_list = service_list;
      service.getProductPropertiesByService(payload).then(properties => {
        properties.data.data.map(el => {
          el.properties.map(elProps => {
            this.productProperties.push({
              name: elProps.name,
              value: elProps.value,
              service: el.name_eng
            });
          });
        });
      });
    },
    addProperties() {
      this.propertiesData.push({ name: "", value: "" });
    },
    deleteProperties(index) {
      this.propertiesData.splice(index, 1);
    },
    changeProperty(name) {
      this.propertiesValue = [];
      this.propertiesValue = this.productProperties.filter(
        res => res.name === name
      );
    }
  }
};
</script>
<style scoped>
.hideUpload > div {
  display: none;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
