<template>
  <el-dialog
    title="Бүтээгдэхүүний сонголтууд"
    :visible.sync="isShowDialog"
    :close-on-click-modal="false"
    width="800px"
    @open="onOpenDialog()"
    @before-close="mCloseFunction()"
    @close="mCloseFunction()"
  >
    <el-row :gutter="10">
      <el-form
        :model="tempVariant"
        ref="variantDialog"
        :rules="variantDialogRules"
        label-position="right"
        label-width="120px"
      >
        <el-form-item label="Монгол нэр" prop="variant_name_mon">
          <el-input v-model="tempVariant.variant_name_mon"></el-input>
        </el-form-item>
        <el-form-item label="Англи нэр" prop="variant_name_eng">
          <el-input v-model="tempVariant.variant_name_eng"></el-input>
        </el-form-item>
        <el-form-item label="Заавал сонгох" prop="is_required">
          <el-switch v-model="tempVariant.is_required"></el-switch>
        </el-form-item>
        <el-form-item label="Олон сонголтот" prop="is_multiple">
          <el-switch v-model="tempVariant.is_multiple"></el-switch>
        </el-form-item>
        <div v-if="tempVariant.is_multiple === true">
          <el-form-item label="Min" prop="select_min">
            <el-input
              type="number"
              v-model.number="tempVariant.select_min"
              style="width:15%"
              :min="1"
              :max="variantOptions.length"
            ></el-input>
          </el-form-item>
          <el-form-item label="Max" prop="select_max">
            <el-input
              type="number"
              @input.native="onChangeMultipleVariantMinMax"
              v-model.number="tempVariant.select_max"
              style="width:15%"
              :min="1"
              :max="variantOptions.length"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
    </el-row>
    <el-row style="border-top: 1px solid #000000">
      <h3>Нэмэлт сонголтууд</h3>
      <div
        v-for="(variant, variantIndex) in variantOptions"
        :key="variantIndex"
      ></div>
      <el-button @click="addVariantOption()" size="mini" type="primary"
        >Нэмэх</el-button
      >
      <el-table :data="variantOptions" style="width: 100%" max-height="250">
        <el-table-column
          prop="option_name_mon"
          label="Сонголтын Монгол нэр"
          width="230"
        >
          <template slot-scope="scope">
            <el-input
              size="small"
              style="text-align: center"
              v-model="scope.row.option_name_mon"
              controls-position="right"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="option_name_eng"
          label="Сонголтын Англи нэр"
          width="230"
        >
          <template slot-scope="scope">
            <el-input
              size="small"
              style="text-align: center"
              v-model="scope.row.option_name_eng"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="option_price"
          label="Сонголтын үнэ"
          width="150"
          :render-header="renderHeader"
        >
          <template slot-scope="scope">
            <el-currency-input
              size="small"
              class="el-input--small"
              style="text-align: center"
              v-model="scope.row.option_price"
            ></el-currency-input>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Үйлдэл" width="120">
          <template slot-scope="scope">
            <el-button
              @click.native.prevent="deleteRow(scope.$index, scope.row)"
              type="text"
              size="small"
            >
              Устгах
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button @click="mCloseFunction()">Болих</el-button>
      <el-button type="primary" @click="mSaveFunction()">Хадгалах</el-button>
    </span>
  </el-dialog>
</template>

<script>
import ElCurrencyInput from "../ElCurrencyInput";
export default {
  name: "VariantDialog",
  props: {
    isShow: Boolean,
    saveFunction: Function,
    closeFunction: Function,
    editData: Object,
    variantIndex: Number,
    dialogType: {
      type: String,
      default() {
        return "add";
      }
    }
  },
  computed: {
    isShowDialog: {
      get() {
        return this.isShow;
      },
      set(val) {
        if (!val) this.closeFunction();
      }
    }
  },
  components: {
    ElCurrencyInput
  },
  data() {
    return {
      variantDialogRules: {
        variant_name_mon: [
          {
            required: true,
            message: "Сонголтийн Монгол нэр оруулна уу",
            trigger: "blur"
          }
        ],
        variant_name_eng: [
          {
            required: true,
            message: "Сонголтийн Англи нэр оруулна уу",
            trigger: "blur"
          }
        ]
        // is_required: [
        //   {
        //     required: true,
        //     message: "Сон",
        //     trigger: "blur"
        //   }
        // ]
      },
      variantOptions: [],
      tempVariant: {
        variant_name_mon: "",
        variant_name_eng: "",
        is_required: false,
        is_multiple: false,
        select_min: 1,
        select_max: 1
      }
    };
  },
  methods: {
    onOpenDialog() {
      if (this.dialogType == "edit") {
        if (this.editData) {
          this.tempVariant = {
            variant_name_mon: JSON.parse(JSON.stringify(this.editData))
              .variant_name_mon,
            variant_name_eng: JSON.parse(JSON.stringify(this.editData))
              .variant_name_eng,
            is_required: JSON.parse(JSON.stringify(this.editData)).is_required,
            is_multiple: JSON.parse(JSON.stringify(this.editData)).is_multiple,
            select_min: parseInt(JSON.stringify(this.editData)).select_min,
            select_max: parseInt(JSON.stringify(this.editData)).select_max
          };
          this.variantOptions = JSON.parse(
            JSON.stringify(this.editData)
          ).options;
        }
      }
    },
    onClickAddVariant() {
      if (!Object.prototype.hasOwnProperty.call(this.menu, "variants")) {
        this.menu.variants = [];
      }
      let tempData = {
        variant_name_mon: "",
        variant_name_eng: "",
        options: []
      };
      this.menu.variants.push(tempData);
      this.$forceUpdate();
    },
    addVariantOption() {
      let temp = {
        option_name_mon: "",
        option_name_eng: "",
        option_price: 0
      };
      this.variantOptions.push(temp);
      this.$forceUpdate();
    },
    deleteRow(index) {
      this.variantOptions.splice(index, 1);
      this.$forceUpdate();
    },
    resetData() {
      this.tempVariant = {
        variant_name_mon: "",
        variant_name_eng: "",
        is_required: false,
        is_multiple: false,
        select_min: 1,
        select_max: 1
      };
      this.variantOptions = [];
    },
    mCloseFunction() {
      this.resetData();
      this.closeFunction();
    },
    mSaveFunction() {
      // this.saveFunction()
      this.$refs.variantDialog.validate(valid => {
        if (valid) {
          let isVariantOptionsValidated = true;
          this.variantOptions.map(el => {
            if (Object.prototype.hasOwnProperty.call(el, "option_name_mon")) {
              if (
                el.option_name_mon == "" ||
                el.option_name_mon == null ||
                el.option_name_mon == undefined
              ) {
                isVariantOptionsValidated = false;
                // return false;
              }
            } else {
              isVariantOptionsValidated = false;
              // return false;
            }
            if (Object.prototype.hasOwnProperty.call(el, "option_name_eng")) {
              if (
                el.option_name_eng == "" ||
                el.option_name_eng == null ||
                el.option_name_eng == undefined
              ) {
                isVariantOptionsValidated = false;
                // return false;
              }
            } else {
              isVariantOptionsValidated = false;
              // return false;
            }
            if (Object.prototype.hasOwnProperty.call(el, "option_price")) {
              if (!Number.isInteger(el.option_price)) {
                isVariantOptionsValidated = false;
                // return false;
              }
            } else {
              isVariantOptionsValidated = false;
              // return false;
            }
          });
          if (this.variantOptions.length == 0) {
            this.$notify({
              title: "Анхааруулга",
              message: "Нэмэлт сонголт хоосон байж болохгүй!",
              type: "warning"
            });
          } else if (isVariantOptionsValidated) {
            let tempData = JSON.parse(JSON.stringify(this.tempVariant));
            tempData.options = this.variantOptions;
            this.saveFunction(tempData, this.dialogType, this.variantIndex);
            this.resetData();
          } else {
            this.$notify({
              title: "Анхааруулга",
              message: "Зарим талбарыг бөглөөгүй байна.",
              type: "warning"
            });
          }
        } else {
          this.$notify({
            title: "Анхааруулга",
            message: "Зарим талбарыг бөглөөгүй байна.",
            type: "warning"
          });
          return false;
        }
      });
    },
    renderHeader(h, { column }) {
      const paymentContent = h(
        "div",
        {
          slot: "content"
        },
        "Сонгоход нэмэгдэх үнэ"
      );
      return h("div", [
        h("span", column.label),
        h(
          "el-tooltip",
          {
            props: {
              placement: "top"
            }
          },
          [
            column.label == "Сонголтын үнэ" ? paymentContent : null,
            h("i", {
              class: "el-icon-warning-outline",
              style: "color:orange;margin-left:5px;"
            })
          ]
        )
      ]);
    },
    onChangeMultipleVariantMinMax(event) {
      if (parseInt(event.target.value) > parseInt(this.variantOptions.length)) {
        this.tempVariant.select_max =
          this.variantOptions.length == 0 ? 1 : this.variantOptions.length;
      }
    }
  }
};
</script>

<style></style>
